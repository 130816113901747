import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function SecurityTesting() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-img">
        <div className="banner-box-container-1">
          <div className="banner-box-container-2">
            <h2>
              Safeguard your applications from cyber threats with efficient
              security testing
            </h2>

            <h2 className="banner-box-container-2-h2">
              Protect Your Business Critical Applications From Cyber
              vulnerabilities
            </h2>

            <div
              className="banner-box-btn"
              onClick={() => {
                scrolltoFooter();
              }}
            >
              <Link to={"/contact"} className="banner-box-btn-link">
                <h2>Talk To Us</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>Security Testing Services</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="service-content-desc">
            <div className="service-content-left-desc">
              <p>
                As the cyber world is becoming more-and-more vulnerable to
                attacks, the security of enterprise, customer data and
                application availability are key concerns for enterprises. Any
                security breach can have wide-spread and far-reaching impacts
                including loss of customer trust and legal repercussions. To
                avoid this situation, we recommend security testing services for
                your application.
              </p>
              <br />
              <p>
                QualiTek, with its team of{" "}
                <b>Certified Ethical Hackers (CEH)</b>, can ensure that your
                application is secure from any vulnerabilities, and meets the
                stated security requirements like confidentiality,
                authorization, authentication, availability and integrity.
              </p>
            </div>
          </div>
          <div className="service-content-right-img">
            <img
              src={require("../../Images/cyber.png")}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            className="service-content-left-img"
            style={{
              position: "relative",
            }}
          >
            <img
              src={require("../../Images/owasp.jpg")}
              style={{ width: "80%" }}
            />
          </div>
          <div className="service-content-desc">
            <div
              className="service-content-left-desc"
              style={{ marginTop: "5%" }}
            >
              <p>
                QualiTek is among the best security testing companies that have
                expertise in assessing a wide range of applications for security
                threats and we ensure that your application is rigorously tested
                for all possible threats and vulnerabilities.
              </p>
              <p>
                We primarily follow the <b>OWASP (Open Web Security Project)</b>{" "}
                guidelines in our security testing services along with{" "}
                <b>PCI-DSS, HIPAA, SOX, WAHH, OSSTM, WASC and NIST Standards</b>{" "}
                as per the application-specific requirements. These include a
                set of comprehensive checks for testing the security of your web
                application and ensuring that no vulnerabilities are missed
                during testing.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default SecurityTesting;
