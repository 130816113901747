import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function APIMicroServicesTesting() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-img">
        <div className="banner-box-container-1">
          <div className="banner-box-container-2">
            <h2>
              Achieve Functionality Compliant Applications With Our Manual
              Testing Services
            </h2>

            <h2 className="banner-box-container-2-h2">
              Launch Bug Free Applications With Coincidence
            </h2>

            <div
              className="banner-box-btn"
              onClick={() => {
                scrolltoFooter();
              }}
            >
              <Link to={"/contact"} className="banner-box-btn-link">
                <h2>Talk To Us</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>API and Web Services Testing</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                Organizations have adopted modern architecture involving Cloud
                services and Mobile, and the result we see is a large composite
                system that sits behind these simple-looking applications. The
                content of the message layer and business logic is very critical
                to the successful operation of these applications. With the
                architecture following certain protocols, such as XML, SOAP,
                REST and others, the big question becomes – how to validate our
                Business Applications.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>QualiTek Differentiators</h2>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="service-content-left-desc">
            <ul
              className="service-differtiater-list-body"
              style={{ marginLeft: "10%" }}
            >
              <li className="service-differtiater-list-item">
                Unit testing – for checking the functionality of individual
                APIs.
              </li>
              <li className="service-differtiater-list-item">
                Load testing – for validation of functionality and performance
                for the system under various levels of user/ transaction load.
              </li>
              <li className="service-differtiater-list-item">
                Security testing – Involves various types of security checks
                like penetration testing, authentication, encryption, and access
                control.
              </li>
            </ul>
          </div>

          <div className="service-content-left-desc">
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Functional testing – for testing end to end functionality of the
                API layer.
              </li>
              <li className="service-differtiater-list-item">
                Runtime error detection – Execution of Automated / Manual tests
                to identify problems, such as exceptions and resource leaks.
              </li>
              <li className="service-differtiater-list-item">
                Web UI testing – end to end testing of the entire system using
                the APIs.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>QualiTek API Testing Methodologies Includes Following Steps</h2>
        </div>

        <div
          className="service-content-right-img"
          style={{
            position: "relative",

            width: "60%",
            height: "auto",
            display: "flex",
            alignSelf: "center",
          }}
        >
          <img
            src={require("../../Images/API-testing-img-1.png")}
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default APIMicroServicesTesting;
