import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function UsabilityTesting() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-img">
        <div className="banner-box-container-1">
          <div className="banner-box-container-2">
            <h2>
              Gain real-time insights into applications with usability testing
              services
            </h2>

            <h2 className="banner-box-container-2-h2">
              Optimize Your Applications For Better User Experience
            </h2>

            <div
              className="banner-box-btn"
              onClick={() => {
                scrolltoFooter();
              }}
            >
              <Link to={"/contact"} className="banner-box-btn-link">
                <h2>Talk To Us</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>Usability Testing Services</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                Do your customers have to navigate through a maze of actions on
                your application to achieve what they need to? Are your
                customers leaving your website without completing the purchase,
                since they could not find the right link or page?
              </p>
              <br />
              <p>
                These problems can be avoided with Usability Testing, giving
                customers a smooth sailing to do what they want on the
                application.
                <br />
              </p>
              <p>
                Poor usability can affect your customer loyalty, putting a
                direct impact on your sales and brand reputation. The
                application should be easy to learn, use and navigate and should
                meet customers’ expectations. Software usability testing will
                help you identify all the usability issues before the
                application is released to the end-users, providing an intuitive
                user experience for your customers.
              </p>
            </div>
          </div>
          <div
            className="service-content-right-img"
            style={{
              position: "relative",

              width: "70%",
              height: "auto",
              display: "flex",
              alignSelf: "center",
            }}
          >
            <img
              src={require("../../Images/usability-img-1.png")}
              style={{ width: "100%" }}
            />
          </div>
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                QualiTek's team of Usability experts will help you with the
                user-experience of your application and come up with a Usability
                test strategy based on the type of users, demographics, key
                business scenarios, etc. The team will identify a set of
                appropriate users, draft survey questions and help the targeted
                users complete the survey. The user behavior and actions will be
                monitored in real-time along with conducting interviews to get
                deeper insights about the application’s navigational scenarios
                and other options.
              </p>
              <br />
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  QualiTek will provide a detailed Usability analysis report -
                  with the following key parameters:
                </li>
                <li className="service-differtiater-list-item">
                  Average Application Usability Rating
                </li>
                <li className="service-differtiater-list-item">
                  Usability Ratings of different countries
                </li>
                <li className="service-differtiater-list-item">
                  Detailed comments provided by individuals
                </li>
                <li className="service-differtiater-list-item">
                  Average Application Usability Rating Graph
                </li>
                <li className="service-differtiater-list-item">
                  Areas which are easy to access
                </li>
                <li className="service-differtiater-list-item">
                  Areas where improvement is required
                </li>
                <li className="service-differtiater-list-item">
                  Post-Task Average response given by users
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default UsabilityTesting;
