import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "../TestComponents/TestAutomation.css";
import { Link } from "react-router-dom";

function DevOpsAutomation() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-img">
        <div className="banner-box-container-1">
          <div className="banner-box-container-2">
            <h2>
              Accelerate your Time to Market with Faster Releases Embracing Test
              Automation in DevOps
            </h2>

            <div
              className="banner-box-btn"
              onClick={() => {
                scrolltoFooter();
              }}
            >
              <Link to={"/contact"} className="banner-box-btn-link">
                <h2>Talk To Us</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>Test Automation In DevOps</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                Today’s businesses need faster releases and quality products
                along with shorter time-to-market. This need for faster releases
                to get high-performing solutions is achieved by adopting test
                automation in DevOps and agile methodology of software
                development. During the software testing process of the software
                development life cycle, there should be less manual intervention
                and test automation using latest automation tools should be
                leveraged. Undoubtedly, for the success of DevOps practices with
                Continuous Integration (CI) and Continuous Delivery (CD),
                leveraging test automation for continuous testing (CT) using
                latest automation tools is the key for its success.
              </p>
              <br />
              <p>
                Our test automation services best support your business
                objectives by enabling and designing an effective test
                automation strategy that is properly aligned with your business
                goals. We follow an effective and strategic 6 phased test
                automation process to ensure faster and quality releases along
                with ensuring faster time-to-market and quicker ROI.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div className="service-header-box">
          <h2>Our Test Automation Process</h2>
        </div>

        <div
          className="service-content-right-img"
          style={{
            position: "relative",
            width: "80%",
            height: "auto",
          }}
        >
          <img
            src={require("../../Images/devops-automation-img-1.png")}
            style={{ width: "100%" }}
          />
        </div>
        <div
          className="service-content-right-img"
          style={{
            position: "relative",
            width: "80%",
            height: "auto",
          }}
        >
          <img
            src={require("../../Images/devops-automation-img-2.png")}
            style={{ width: "100%" }}
          />
        </div>
        <div
          className="service-content-right-img"
          style={{
            position: "relative",
            width: "80%",
            height: "auto",
          }}
        >
          <img
            src={require("../../Images/devops-automation-img-3.png")}
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            className="service-content-left-desc"
            style={{ marginLeft: "5%" }}
          >
            <h2 className="service-ul-header-h2" style={{ marginLeft: "-5%" }}>
              Benefits with Test Automation in DevOps
            </h2>
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Ensures faster time to market with faster deployments
              </li>
              <li className="service-differtiater-list-item">
                Improves the productivity of business and IT teams
              </li>
              <li className="service-differtiater-list-item">
                Helps to save costs on maintenance and upgrades
              </li>
              <li className="service-differtiater-list-item">
                Delivers quality software products
              </li>
              <li className="service-differtiater-list-item">
                Increases release velocity through CI/CD model
              </li>
              <li className="service-differtiater-list-item">
                Reduces human errors as tasks are automated
              </li>
              <li className="service-differtiater-list-item">
                Ensures cost optimization and maximizes ROI
              </li>
              <li className="service-differtiater-list-item">
                Helps to standardize processes with frameworks for quicker
                testing to enable faster code releases
              </li>
              <li className="service-differtiater-list-item">
                Improves software quality as defects are quickly identified and
                rectified{" "}
              </li>
            </ul>
            <li className="service-differtiater-list-item">
              Improves software quality as defects are quickly identified and
              rectified
            </li>
            <li className="service-differtiater-list-item">
              Improves reliability, and reusability of system components
            </li>
            <li className="service-differtiater-list-item">
              Delivers improved customer experience with quality products
              released to market
            </li>
            <li className="service-differtiater-list-item">
              Ensures greater success for digitization, IoT and other enterprise
              transformation projects
            </li>
          </div>
          <div
            className="service-content-left-img"
            style={{ marginRight: "5%" }}
          >
            <img
              src={require("../../Images/AI-img-3.png")}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div className="service-content-box" style={{ backgroundColor: "#fff" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="service-content-left-desc">
            <h2 className="service-ul-header-h2" style={{ marginLeft: "0%" }}>
              Why Choose us for your Test Automation in DevOps Implementations?
            </h2>
            <ul
              className="service-differtiater-list-body"
              style={{ marginLeft: "5%" }}
            >
              <li className="service-differtiater-list-item">
                10+ years of Test automation expertise with more than 500+ test
                automation experts
              </li>
              <li className="service-differtiater-list-item">
                Quick team scaling based on the project requirement
              </li>
              <li className="service-differtiater-list-item">
                Setting things right from the beginning with detailed automation
                assessment to come up with a clear automation strategy, roadmap
                and RoI using Tx-Automation ROI Calculator
              </li>
              <li className="service-differtiater-list-item">
                Ready to deploy, best in industry automation framework –
                Tx-Automate – implemented with customizations as per your needs
              </li>
              <li className="service-differtiater-list-item">
                Proven end to end selenium automation testing process
              </li>
              <li className="service-differtiater-list-item">
                Expertise in all leading open source and commercial automation
                tools for the web, desktop, and mobile applications
              </li>
              <li className="service-differtiater-list-item">
                Custom test execution reports
              </li>
            </ul>
          </div>
          <div
            className="service-content-right-img"
            style={{
              position: "relative",
              marginTop: "100px",
              marginRight: "5%",
            }}
          >
            <img
              src={require("../../Images/AI-img-4.png")}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default DevOpsAutomation;
