import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "../TestComponents/TestAutomation.css";
import { Link } from "react-router-dom";

function DevOpsCICD() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-img">
        <div className="banner-box-container-1">
          <div className="banner-box-container-2">
            <h2>
              Achieve Accelerated Delivery and Improved Software Quality with
              DevOps CI/CD Implementation
            </h2>

            <h2 className="banner-box-container-2-h2">
              Embrace DevOps CI/CD Best Practices to Get Shorter Delivery Cycles
              and Quality Releases
            </h2>

            <div
              className="banner-box-btn"
              onClick={() => {
                scrolltoFooter();
              }}
            >
              <Link to={"/contact"} className="banner-box-btn-link">
                <h2>Talk To Us</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>DevOps CI/CD Implementation</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                The Philosophy of Continuous integration (CI) is to make small
                code changes and check-in code frequently to a central
                repository and then ensuring that you are making progress in
                terms of features (or bug fixes) while not breaking any existing
                functionality. To be able to check if no existing functionality
                is broken is to check this frequently via automated tests. Thus,
                CI can meaningfully exist only when there is adequate automated
                testing.
              </p>
              <br />
              <p>
                Continuous Delivery (CD) starts its action where CI ends. The
                process of CD includes the automated delivery of applications to
                the assigned infrastructure environments. The DevOps teams work
                across multiple environments such as development, testing and
                production environments, and the CD process ensures there is an
                automated way to push code changes to these environments.
              </p>
              <br />
              <p>
                Thus, the practices of CI and CD typically embody a specific
                culture, a set of DevOps operating principles, and a collection
                of best automation practices that enables the development teams
                to deliver code changes more frequently and reliably. This
                specific implementation is known as CI/CD pipeline as the
                process ensures code quality and security as deployments are
                automated.
              </p>
              <br />
              <p>
                CI/CD practices requires continuous testing (CT) to ensure
                quality deployments as the basic objective of any project is to
                deliver quality software. This process of continuous testing is
                implemented using test automation tools with regression,
                performance, security, and other testing methods which are
                executed within the CI/CD pipeline.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="service-content-left-desc">
            <h2 className="service-ul-header-h2" style={{ marginLeft: "0%" }}>
              Identify Right Tools and Technologies:
            </h2>
            <ul
              className="service-differtiater-list-body"
              style={{ marginLeft: "-25%" }}
            >
              <li className="service-differtiater-list-item">
                Assess current tools and technologies in place
              </li>
              <li className="service-differtiater-list-item">
                Perform tool feasibility analysis
              </li>
              <li className="service-differtiater-list-item">
                Choose the best tool to ensure effective process implementation
              </li>
              <li className="service-differtiater-list-item">
                Recommend the best feasible tool for the implementation
              </li>
            </ul>
          </div>
          <div
            className="service-content-right-img"
            style={{ marginRight: "5%" }}
          >
            <img
              src={require("../../Images/devops-ci-cd-img-4.jpg")}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div className="service-content-box" style={{ backgroundColor: "#fff" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            className="service-content-left-img"
            style={{ position: "relative", marginTop: "100px" }}
          >
            <img
              src={require("../../Images/devops-ci-cd-img-3.jpg")}
              style={{ width: "100%" }}
            />
          </div>
          <div className="service-content-left-desc">
            <h2 className="service-ul-header-h2" style={{ marginLeft: "0%" }}>
              Create and Enhance CI/CD Pipelines:
            </h2>
            <ul
              className="service-differtiater-list-body"
              style={{ marginLeft: "5%" }}
            >
              <li className="service-differtiater-list-item">
                Automation ensures faster and quality releases
              </li>
              <li className="service-differtiater-list-item">
                Breaks the silos between teams and improves enhanced team
                collaboration
              </li>
              <li className="service-differtiater-list-item">
                Improves code quality and delivers faster releases
              </li>
              <li className="service-differtiater-list-item">
                Shortens time-to-market of new features
              </li>
              <li className="service-differtiater-list-item">
                Automation reduces costs and manual interventions
              </li>
              <li className="service-differtiater-list-item">
                Streamlines communication between teams with rapid feedback loop
              </li>
              <li className="service-differtiater-list-item">
                Delivers comprehensive metrics about application performance
              </li>
              <li className="service-differtiater-list-item">
                Ensures code security and code quality
              </li>
              <li className="service-differtiater-list-item">
                Improves the customer satisfaction
              </li>
              <li className="service-differtiater-list-item">
                Capture the best benefits with our deep industry expertise with
                respect to DevOps tools and developing custom automation
                frameworks
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            className="service-content-left-desc"
            style={{ marginLeft: "5%" }}
          >
            <h2 className="service-ul-header-h2" style={{ marginLeft: "-5%" }}>
              Train Client Teams on CI/CD Pipelines:
            </h2>
            <ul
              className="service-differtiater-list-body"
              style={{ marginLeft: "-25%" }}
            >
              <li className="service-differtiater-list-item">
                Ensure the client team handles the CI/CD pipeline creation
              </li>
              <li className="service-differtiater-list-item">
                Enable effective training about the usage of various tools and
                technologies
              </li>
              <li className="service-differtiater-list-item">
                Ensure the pipelines run successfully
              </li>
            </ul>
          </div>
          <div
            className="service-content-right-img"
            style={{ marginRight: "5%" }}
          >
            <img
              src={require("../../Images/devops-ci-cd-img-2.jpg")}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div className="service-content-box" style={{ backgroundColor: "#fff" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            className="service-content-left-img"
            style={{ position: "relative", marginTop: "100px" }}
          >
            <img
              src={require("../../Images/devops-ci-cd-img-1.jpg")}
              style={{ width: "100%" }}
            />
          </div>
          <div className="service-content-left-desc">
            <h2 className="service-ul-header-h2" style={{ marginLeft: "0%" }}>
              Why should you choose us for your DevOps CI/CD implementations?
            </h2>
            <ul
              className="service-differtiater-list-body"
              style={{ marginLeft: "5%" }}
            >
              <li className="service-differtiater-list-item">
                10+ years of experience in testing services
              </li>
              <li className="service-differtiater-list-item">
                Dedicated DevOps expert team ensure scalable, secure and
                reliable testing
              </li>
              <li className="service-differtiater-list-item">
                Ensure automation with deployments and rollbacks performed in a
                click with low risks and high productivity
              </li>
              <li className="service-differtiater-list-item">
                Deliver hassle free project management with flexible engagement
                models
              </li>
              <li className="service-differtiater-list-item">
                Recommend the best DevOps toolchain that best suits your project
                model
              </li>
              <li className="service-differtiater-list-item">
                Focus closely on user security and protection
              </li>
              <li className="service-differtiater-list-item">
                Expertise on industry leading test automation (e.g. UFT,
                Selenium, TestComplete, Coded UI, etc.), Agile development tools
                (e.g. Rally, Scrumdo) and CI/CD tools (e.g. Jenkins, Chef,
                Puppet, TFS, Hudson, Go, Bamboo, etc.) providing lifecycle
                automation
              </li>
              <li className="service-differtiater-list-item">
                Certified automation experts with expertise in implementing
                advanced automation frameworks
              </li>
              <li className="service-differtiater-list-item">
                Standardized processes, templates and toolkits for Agile and
                DevOps QA
              </li>
              <li className="service-differtiater-list-item">
                Global team of QA professionals with a capability to scale up at
                both onsite and offshore
              </li>
              <li className="service-differtiater-list-item">
                Enable continuous support and training for client teams
              </li>
              <li className="service-differtiater-list-item">
                Version control system roll out with appropriate branching
                strategy
              </li>
              <li className="service-differtiater-list-item">
                Continuous Integration- Compile, validate, code review, unit
                testing and integration testing
              </li>
              <li className="service-differtiater-list-item">
                Continuous Delivery- Deploy the build application to test
                servers, and perform UAT
              </li>
              <li className="service-differtiater-list-item">
                Continuous Deployment- Deploy the tested application on the
                production server for release
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default DevOpsCICD;
