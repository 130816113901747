import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Navbar";
import TestAutomation from "./Components/TestComponents/TestAutomation";
import ManualTesting from "./Components/TestComponents/ManualTesting";
import AccessibilityTesting from "./Components/TestComponents/AccessibilityTesting";
import AITesting from "./Components/TestComponents/AITesting";
import APIMicroServicesTesting from "./Components/TestComponents/APIMicroServicesTesting";
import DataMigrationTesting from "./Components/TestComponents/DataMigrationTesting";
import DataWarehousingTesting from "./Components/TestComponents/DataWarehousingTesting";
import EASTesting from "./Components/TestComponents/EASTesting";
import IoTTesting from "./Components/TestComponents/IoTTesting";
import PerformanceTesting from "./Components/TestComponents/PerformanceTesting";
import RPATesting from "./Components/TestComponents/RPATesting";
import SecurityTesting from "./Components/TestComponents/SecurityTesting";
import TCoESetup from "./Components/TestComponents/TCoESetup";
import TestAdvisory from "./Components/TestComponents/TestAdvisory";
import TestDataManagement from "./Components/TestComponents/TestDataManagement";
import UsabilityTesting from "./Components/TestComponents/UsabilityTesting";
import MobileApplicationTesting from "./Components/TestComponents/MobileApplicationTesting";
import DevOpsAutomation from "./Components/DevOpsComponents/DevOpsAutomation";
import DevOpsImplementation from "./Components/DevOpsComponents/DevOpsImplementation";
import DevOpsCICD from "./Components/DevOpsComponents/DevOpsCICD";
import DevOpsConsulting from "./Components/DevOpsComponents/DevOpsConsulting";
import DevOpsTransformation from "./Components/DevOpsComponents/DevOpsTransformation";
import Home from "./Components/AllTabs/Homepage";
import AboutUs from "./Components/AllTabs/AboutUs";
import Careers from "./Components/AllTabs/Careers";
import DevopsServices from "./Components/AllTabs/DevopsServices";
import TestingServices from "./Components/AllTabs/TestingServices";
import Contact from "./Components/AllTabs/Contact";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/aboutus" element={<AboutUs />} />
          <Route exact path="/careers" element={<Careers />} />
          <Route exact path="/devops" element={<DevopsServices />} />
          <Route exact path="/testing" element={<TestingServices />} />

          <Route
            exact
            path="/devops-consulting"
            element={<DevOpsConsulting />}
          />
          <Route
            exact
            path="/devops-implementation"
            element={<DevOpsImplementation />}
          />
          <Route
            exact
            path="/devops-ci-cd-implementations"
            element={<DevOpsCICD />}
          />
          <Route
            exact
            path="/testautomation-in-devops"
            element={<DevOpsAutomation />}
          />
          <Route
            exact
            path="/devops-transformation"
            element={<DevOpsTransformation />}
          />

          <Route exact path="/testautomation" element={<TestAutomation />} />
          <Route
            exact
            path="/manualtesting"
            element={<ManualTesting />}
          ></Route>
          <Route
            exact
            path="/mobileapptesting"
            element={<MobileApplicationTesting />}
          ></Route>
          <Route
            exact
            path="/accessibilitytesting"
            element={<AccessibilityTesting />}
          ></Route>
          <Route exact path="/aitesting" element={<AITesting />}></Route>
          <Route
            exact
            path="/apimicroservicestesting"
            element={<APIMicroServicesTesting />}
          ></Route>
          <Route
            exact
            path="/datamigrationtesting"
            element={<DataMigrationTesting />}
          ></Route>
          <Route
            exact
            path="/datawarehousingtesting"
            element={<DataWarehousingTesting />}
          ></Route>
          <Route exact path="/eastesting" element={<EASTesting />}></Route>
          <Route exact path="/iottesting" element={<IoTTesting />}></Route>
          <Route
            exact
            path="/performancetesting"
            element={<PerformanceTesting />}
          ></Route>

          <Route exact path="/rpatesting" element={<RPATesting />}></Route>
          <Route
            exact
            path="/securitytesting"
            element={<SecurityTesting />}
          ></Route>
          <Route exact path="/tcoesetup" element={<TCoESetup />}></Route>
          <Route exact path="/testadvisory" element={<TestAdvisory />}></Route>
          <Route
            exact
            path="/testdatamanagement"
            element={<TestDataManagement />}
          ></Route>
          <Route
            exact
            path="/usabilitytesting"
            element={<UsabilityTesting />}
          ></Route>
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
