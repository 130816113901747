import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function ManualTesting() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-img">
        <div className="banner-box-container-1">
          <div className="banner-box-container-2">
            <h2>
              Achieve Functionality Compliant Applications With Our Manual
              Testing Services
            </h2>

            <h2 className="banner-box-container-2-h2">
              Launch Bug Free Applications With Confidence
            </h2>

            <div
              className="banner-box-btn"
              onClick={() => {
                scrolltoFooter();
              }}
            >
              <Link to={"/contact"} className="banner-box-btn-link">
                <h2>Talk To Us</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>Manual Testing Services</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                As the applications and systems become more complex, enterprises
                face unique challenges in functional testing to achieve the
                ‘perfect’ production quality for software. With robust testing
                processes and technology experience, corporations need strong
                domain skills
                <br />
                for performing effective functional testing.
              </p>
              <br />
              <p>
                QualiTek brings a unique blend of testing processes, tools and
                technology expertise, and domain knowledge to ensure that the
                product is delivered without defects in an accelerated time
                frame. QualiTek provides end to end manual testing services for
                your functional testing needs with a foundation of matured test
                processes, in-house accelerators, and experience of all
                industry-leading functional testing tools.
              </p>
              <br />
              <p>
                We undertake the complete responsibility for the testing
                activities from creating an optimized test plan to post
                production support. We adopt a user-centric testing approach,
                delivering continuous value improvement in our engagements. We
                ensure complete traceability of the business requirements to the
                test cases designed and executed for extensive test coverage. As
                a professional company for manual testing, we provide extensive
                reporting with customized dashboards at regular intervals to
                keep you on top of the testing progress and defects.
              </p>
            </div>
          </div>
          <div
            className="service-content-right-img"
            style={{
              position: "relative",
              width: "80%",
              height: "auto",
              display: "flex",
              alignSelf: "center",
            }}
          >
            <img
              src={require("../../Images/Manual-Software-Testing.webp")}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>QualiTek Differentiators</h2>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="service-content-left-img">
            <img
              src={require("../../Images/manual-testing-img-1.png")}
              style={{ width: "100%" }}
            />
          </div>

          <div className="service-content-right-desc">
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Expertise on a wide range of applications, including the web,
                desktop, mobile, packaged products etc.
              </li>
              <li className="service-differtiater-list-item">
                Support various development methodologies, like Agile,
                waterfall, DevOps, and Hybrid models and work collaboratively
                with the development teams.
              </li>
              <li className="service-differtiater-list-item">
                Involved early in the project lifecycle to lower the cost of
                quality.
              </li>
              <li className="service-differtiater-list-item">
                Testing professionals with comprehensive experience across
                domains.
              </li>
              <li className="service-differtiater-list-item">
                Test requirement techniques to ensure Bi-Directional
                traceability.
              </li>
              <li className="service-differtiater-list-item">
                Organized approach to testing and the organization of test cases
                to promote repeatability.
              </li>
              <li className="service-differtiater-list-item">
                Risk-based Testing Methodology addressing Business, Project, and
                Technology risks.
              </li>
              <li className="service-differtiater-list-item">
                Formalized defect documentation processes to ensure
                reproducibility of defects.
              </li>
              <li className="service-differtiater-list-item">
                Expertise on all leading tools for application lifecycle
                management, test management, defect management, test data
                management etc.
              </li>
              <li className="service-differtiater-list-item">
                Structured project communications, including daily, weekly, and
                monthly status reports with detailed test execution status and
                analysis delivered through a customized dashboard.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default ManualTesting;
