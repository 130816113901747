export const navItems = [
  {
    id: 1,
    title: "Services",
    path: "./home",
    cName: "nav-item",
  },
  /*{
    id: 2,
    title: "Industries",
    path: "./industries",
    cName: "nav-item",
  },*/
  {
    id: 3,
    title: "Careers",
    path: "./careers",
    cName: "nav-item",
  },
  {
    id: 4,
    title: "Company",
    path: "./aboutus",
    cName: "nav-item",
  },
  {
    id: 4,
    title: "Contact Us",
    path: "./contact",
    cName: "nav-item",
  },
];

export const serviceDropdown = [
  {
    id: 1,
    title: "AutomationTesting",
    path: "./automationtesting",
    cName: "submenu-item",
  },
  {
    id: 2,
    title: "Consulting",
    path: "./consulting",
    cName: "submenu-item",
  },
  {
    id: 3,
    title: "Design",
    path: "./design",
    cName: "submenu-item",
  },
  {
    id: 4,
    title: "Development",
    path: "./development",
    cName: "submenu-item",
  },
];
