import React, { useState } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "./Navbar.css";
import { navItems } from "./NavItems";
import Dropdown from "./Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function Navbar() {
  const [dropdown, setDropdown] = useState(false);
  const [industriesdropdown, setIndustriesDropdown] = useState(false);
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  return (
    <>
      <div className="navbar">
        <div className="nav-area">
          <div className="navbar-logo-box">
            <Link to={"./home"}>
              <img
                className="navbar-logo"
                src={require("../Images/QualiTek_Logo.png")}
                style={{ height: "80%", width: "80%" }}
              />
            </Link>
            <div className="navbar-logo-caption-div">
              <span className="navbar-logo-caption-span">
                A VNC-XCEL Corp Company
              </span>
            </div>
          </div>

          <div className="menu-icon" onClick={handleClick}>
            <FontAwesomeIcon icon={faBars} />
          </div>

          <ul className="nav-items">
            {navItems.map((item) => {
              if (item.title === "Services") {
                return (
                  <li
                    key={item.id}
                    className={item.cName}
                    onMouseEnter={() => setDropdown(true)}
                    onMouseLeave={() => setDropdown(false)}
                  >
                    <Link className="nav-links" to={item.path}>
                      {item.title}
                    </Link>
                    {dropdown && <Dropdown />}
                  </li>
                );
              }
              return (
                <li key={item.id} className={item.cName}>
                  <Link className="nav-links" to={item.path}>
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Navbar;
