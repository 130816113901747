import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./SwiperSlider/SwiperSlider.css";
import "./ImageSlider.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default function ImageSlider({ footerRef }) {
  let partnerElements = [];
  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView({
        top: footerRef.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  function displayCLients() {
    return (
      <>
        <SwiperSlide>
          <div className="slide-container">
            <video
              style={{
                width: "100%",
                height: "100%",
                resize: "both",
              }}
              autoPlay
              loop
              muted
              playbackspeed="0.5"
              webkit-playsinline
            >
              <source
                src={require("../Images/gaintwheel.mp4")}
                type="video/mp4"
              />
            </video>
            <div className="banner-box">
              <div className="banner-caption">
                <h2>How We Create 360&deg; Value</h2>
                <h4>
                  Everyday in All directions We measure Our value by the quality
                  we deliver for all our stake holders
                </h4>
              </div>

              <div
                className="banner-btn"
                onClick={() => {
                  scrolltoFooter();
                }}
              >
                <Link to={"/contact"}>
                  <h2>Know More About Us</h2>
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide-container">
            <video
              style={{ width: "100%", height: "100%" }}
              autoPlay
              loop
              muted
              playbackspeed="0.5"
            >
              <source
                src={require("../Images/customer.mp4")}
                type="video/mp4"
              />
            </video>
            <div className="banner-box">
              <div className="banner-caption">
                <h2>Customer-First & Customer-Centric Processes.</h2>

                <h4>Experience customer-centricity to life-centricity</h4>
              </div>
              <div
                className="banner-btn"
                onClick={() => {
                  scrolltoFooter();
                }}
              >
                <Link to={"/contact"}>
                  <h2>Become Our Valued Customer</h2>
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide-container">
            <video
              style={{ width: "100%", height: "100%" }}
              autoPlay
              loop
              muted
              playbackspeed="0.5"
            >
              <source
                src={require("../Images/basketball.mp4")}
                type="video/mp4"
              />
            </video>
            <div className="banner-box">
              <div className="banner-caption">
                <h2>Everything Starts with Ambition</h2>
                <h4>
                  We turn bold Ideas into groundbreaking business solutions for
                  a tommorow built on technology
                </h4>
              </div>
              <div
                className="banner-btn"
                onClick={() => {
                  scrolltoFooter();
                }}
              >
                <Link to={"/contact"}>
                  <h2>Let Us know Ur Ambition</h2>
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide-container">
            <video
              style={{ width: "100%", height: "100%" }}
              autoPlay
              loop
              muted
              playbackspeed="0.5"
            >
              <source
                src={require("../Images/pendulum.mp4")}
                type="video/mp4"
              />
            </video>
            <div className="banner-box">
              <div className="banner-caption">
                <h2>Connection. Communication</h2>
                <h4>
                  Creating value for Businnes through Omni Connected experiences
                </h4>
              </div>
              <div
                className="banner-btn"
                onClick={() => {
                  scrolltoFooter();
                }}
              >
                <Link to={"/contact"}>
                  <h2>Know Us and Connect With Us</h2>
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </>
    );
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Swiper
        autoHeight={true}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        speed={10}
        pagination={{
          el: `.my-custom-pagination-div`,
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '">  </span>';
          },
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        slidesPerView={"auto"}
      >
        {displayCLients()}
      </Swiper>
    </div>
  );
}
