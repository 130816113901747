import "../../App.css";
import "./Homepage.css";
import Partners from "../Partners/Partners";

import React, { useState, useRef } from "react";

import ImageSlider from "../ImageSlider";
import Footer1 from "../Footer1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltUp } from "@fortawesome/free-solid-svg-icons";

function HomePage() {
  const footerRef = useRef(null);
  const homeRef = useRef(null);
  const keyserviceRef = useRef(null);
  const testimonialRef = useRef(null);
  const devopsRef = useRef(null);
  const [hoverImage, setHoverImage] = useState("");

  const handleScroll = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        top: ref.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      <div className="progressive-nav-container">
        <div
          className="progressive-nav-item"
          onClick={() => {
            handleScroll(homeRef);
          }}
        >
          <FontAwesomeIcon icon={faLongArrowAltUp} size="sm" />
        </div>
        <div
          onClick={() => {
            handleScroll(keyserviceRef);
          }}
          className="progressive-nav-item"
        >
          <p>Key Services</p>
        </div>
        <div
          onClick={() => {
            handleScroll(testimonialRef);
          }}
          className="progressive-nav-item"
        >
          <p>What Our clients say</p>
        </div>
        <div
          onClick={() => {
            handleScroll(devopsRef);
          }}
          className="progressive-nav-item"
        >
          <p>Devops Services</p>
        </div>
        <div
          onClick={() => {
            handleScroll(footerRef);
          }}
          className="progressive-nav-item"
        >
          <p>ReachUs</p>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <div ref={homeRef} className="box1">
          <ImageSlider footerRef={footerRef} />
        </div>

        <div className="keyservcies-section">
          <h2>
            Every day, We measure our Success by the Quality We have attained
            for the Customer !
          </h2>
          <img
            className="keyservices-section-img"
            src={require("../../Images/QualiTek-Symbol.png")}
          />
          <h2>Key Services</h2>
          <div
            ref={keyserviceRef}
            key="keyservicesId"
            className="keyservcies-img-ext-bottom"
          ></div>
        </div>

        <div className="keyservices-container">
          <div className="keyservices-row">
            <div
              className="keyservice"
              id="keyservice-01"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-8.webp") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-8.webp") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2 className="service-name">Manual Testing</h2>
                <p></p>
                <p>
                  Manual functional testing of applications to ensure all
                  features work as expected.
                </p>
                <p></p>
              </div>
            </div>
            <div
              className="keyservice"
              id="keyservice-02"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-3.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-3.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>Test Automation</h2>
                <p></p>
                <p>
                  Automation of regression test suites to achieve lower testing
                  costs and faster time to market.
                </p>
                <p></p>
              </div>
            </div>

            <div
              className="keyservice"
              id="keyservice-03"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-7.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-7.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>Performance Testing</h2>
                <p></p>
                <p>
                  Testing for Speed, stability and scalability to ensure the
                  applications performs well under peak load.
                </p>
                <p></p>
              </div>
            </div>
          </div>

          <div className="keyservices-row">
            <div
              className="keyservice"
              id="keyservice-04"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-5.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-5.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>Security Testing</h2>
                <p></p>
                <p>
                  Testing to ensure the applications are secure against any
                  vulnerabilities.
                </p>
                <p></p>
              </div>
            </div>

            <div
              className="keyservice"
              id="keyservice-05"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-10.webp") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-10.webp") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>MobileApp Testing</h2>
                <p></p>
                <p>Android IOS and all other Platforms, Hybrid Apps Testing</p>
                <p></p>
              </div>
            </div>
            <div
              className="keyservice"
              id="keyservice-06"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-2.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-2.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2 style={{ color: "white" }}>Digital Testing</h2>
                <p></p>
                <p>
                  E2E functional & non-functional testing of digital apps
                  including social,mobile,analytics & cloud.
                </p>
                <p></p>
              </div>
            </div>
          </div>
          <div className="keyservices-row">
            <div
              className="keyservice"
              id="keyservice-07"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-9.png") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-9.png") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              {" "}
              <div className="keyservice-ovrlay">
                <h2 style={{ color: "white" }}>DW/Analytics</h2>
                <p></p>
                <p>Testing for end to end DW and Analytics value chain.</p>
                <p></p>
              </div>
            </div>
            <div
              className="keyservice"
              id="keyservice-08"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-1.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-1.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>DevOps &amp; Agile Automation</h2>
                <p></p>
                <p>Testing aligned to DevOps and Agile methodologies.</p>
                <p></p>
              </div>
            </div>

            <div
              className="keyservice"
              id="keyservice-09"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-4.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-4.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>Test Advisory Service</h2>
                <p></p>
                <p>
                  Review of entire Testing organization including Processes,
                  People and Tools &amp; Technologies.
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </div>

        <div
          ref={testimonialRef}
          key="testimonialsId"
          style={{
            height: 5,
            width: "100%",
            backgroundColor: "white",
          }}
        ></div>

        <div ref={devopsRef} key="devopsId" className="devops-banner-container">
          <video
            style={{ width: "100%", height: "100%" }}
            autoPlay
            loop
            muted
            playbackspeed="0.5"
          >
            <source src={require("../../Images/clouds.mp4")} type="video/mp4" />
          </video>
          <div className="devops-keyservices-banner-box">
            <div className="devops-keyservices-banner-caption">
              <h2>Make progress thrive with cloud-led innovation</h2>

              <h3>
                Initiate your <strong>Dev-Ops Journey</strong> with us
              </h3>
            </div>

            <img
              src={require("../../Images/QualiTek-Symbol.png")}
              className="keyservices-section-img"
            />
            <h1 className="devops-keyservices-banner-caption-h2">
              Devops Services
            </h1>

            <div
              className="keyservcies-img-ext-bottom"
              style={{ marginTop: "1%", borderTopColor: "orangered" }}
            ></div>
          </div>
        </div>

        <div className="keyservices-container">
          <div className="keyservices-row" style={{ width: "80%" }}>
            <div
              className="keyservice"
              style={{
                backgroundImage:
                  "url(" +
                  require("../../Images/devops-keyservice-img-1.jpg") +
                  ")",
                backgroundSize: "100% 100%",
                margin: "2%",
              }}
            >
              <div className="keyservice-ovrlay">
                <h2>DevOps Consulting</h2>
                <p></p>
                <p>Testing aligned to DevOps and Agile methodologies.</p>
                <p></p>
              </div>
            </div>
            <div
              className="keyservice"
              style={{
                backgroundImage:
                  "url(" +
                  require("../../Images/devops-keyservice-img-2.jpg") +
                  ")",
                backgroundSize: "100% 100%",
                margin: "2%",
              }}
            >
              <div className="keyservice-ovrlay">
                <h2 style={{ color: "white" }}>Devops Transformation</h2>
                <p></p>
                <p>
                  E2E functional & non-functional testing of digital apps
                  including social,mobile,analytics & cloud.
                </p>
                <p></p>
              </div>
            </div>
            <div
              className="keyservice"
              style={{
                backgroundImage:
                  "url(" +
                  require("../../Images/devops-keyservice-img-5.jpg") +
                  ")",
                backgroundSize: "100% 100%",
                margin: "2%",
              }}
            >
              <div className="keyservice-ovrlay">
                <h2>Devops Implementation</h2>
                <p></p>
                <p>
                  Automation of regression test suites to achieve lower testing
                  costs and faster time to market.
                </p>
                <p></p>
              </div>
            </div>
          </div>
          <div className="keyservices-row" style={{ width: "80%" }}>
            <div
              className="keyservice"
              style={{
                backgroundImage:
                  "url(" +
                  require("../../Images/devops-keyservice-img-3.jpg") +
                  ")",
                backgroundSize: "100% 100%",
                margin: "2%",
              }}
            >
              <div className="keyservice-ovrlay">
                <h2>Devops Containerization</h2>
                <p></p>
                <p>
                  Review of entire Testing organization including Processes,
                  People and Tools &amp; Technologies.
                </p>
                <p></p>
              </div>
            </div>
            <div
              className="keyservice"
              style={{
                backgroundImage:
                  "url(" +
                  require("../../Images/devops-keyservice-img-4.jpg") +
                  ")",
                backgroundSize: "100% 100%",
                margin: "2%",
              }}
            >
              <div className="keyservice-ovrlay">
                <h2>Devops CI/CD</h2>
                <p></p>
                <p>Android IOS and all other Platforms, Hybrid Apps Testing</p>
                <p></p>
              </div>
            </div>

            <div
              className="keyservice"
              style={{
                backgroundImage:
                  "url(" +
                  require("../../Images/devops-keyservice-img-6.jpg") +
                  ")",
                backgroundSize: "100% 100%",
                margin: "2%",
              }}
            >
              <div className="keyservice-ovrlay">
                <h2>Devops Test Automation</h2>
                <p></p>
                <p>
                  Testing for Speed, stability and scalability to ensure the
                  applications performs well under peak load.
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default HomePage;
