import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function EASTesting() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-img">
        <div className="banner-box-container-1">
          <div className="banner-box-container-2">
            <h2>
              Achieve Functionality Compliant Applications With Our Manual
              Testing Services
            </h2>

            <h2 className="banner-box-container-2-h2">
              Launch Bug Free Applications With Confidence
            </h2>

            <div
              className="banner-box-btn"
              onClick={() => {
                scrolltoFooter();
              }}
            >
              <Link to={"/contact"} className="banner-box-btn-link">
                <h2>Talk To Us</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>Enterprise Applications Quality Assurance Services</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                Digital transformation is boosting the speed, innovation, and
                agility of IT initiatives. Enterprise Resource Planning (ERP),
                Customer Relationship Management(CRM), and Business Process
                Management (BPM) systems are at the core of this digital
                transformation, essentially integrating back office with
                frontend applications. Further, these systems seamlessly connect
                critical business processes across various corporate functions
                like Finance, Human Resources, Supply Chain, Sales & Marketing.
              </p>

              <p>
                The Post-modern ERP applications like SAP S/4 HANA, Oracle
                Cloud, Microsoft Dynamics, and the Next-gen CRM applications
                like Salesforce are transforming the User Experience offering
                promising capabilities, both technologically and functionally.
                BPM products like Pega help in adopting next-gen business models
                that innovate, automate, and transform businesses at scale and
                speed.
              </p>

              <p>
                According to various studies, with the volume and velocity of
                changes to the ERP, CRM, and BPM systems, customers find that
                their traditional testing methods require significant changes to
                achieve their business goals. QualiTek brings to you the best in
                class Enterprise Applications Quality Assurance practice that
                leverages the industry’s best practices, including RPA,
                Artificial Intelligence, Machine learning-driven, and
                DevOps-driven Continuous testing
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default EASTesting;
