import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function PerformanceTesting() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-img">
        <div className="banner-box-container-1">
          <div className="banner-box-container-2">
            <h2>
              Performance and load testing delivers stable applications at peak
              loads
            </h2>

            <h2 className="banner-box-container-2-h2">
              Predict Application Performance At High Loads To Ensure Customer
              Loyalty
            </h2>

            <div
              className="banner-box-btn"
              onClick={() => {
                scrolltoFooter();
              }}
            >
              <Link to={"/contact"} className="banner-box-btn-link">
                <h2>Talk To Us</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>Performance Testing Services</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                The reliable and consistent performance of your business
                critical applications is the key to success of your enterprise.
                However, performance issues within an application are often
                identified in live scenarios which result in considerable damage
                to the reputation of your brand. That is where formal web
                performance testing of the applications is necessary to
                ascertain application behavior at peak performance and
                scalability levels.
              </p>
              <p>
                QualiTek help you predict application behavior and performance
                in a simulated, real-life environment, thus saving you from
                unpleasant events later, which frustrate customers and impact
                profitability. We ensure the application is responsive and
                reliable enough to respond to peak load days like a black Friday
                sale and can also scale to other future events like product
                release, acquisitions, new clients, regulatory changes and
                ongoing business growth. We also ensure the applications can
                meet any
                <br />
                contractual obligations and SLAs for performance.
              </p>
            </div>
          </div>
          <div
            className="service-content-right-img"
            style={{
              position: "relative",
              width: "90%",
              height: "auto",
              display: "flex",
              alignSelf: "center",
            }}
          >
            <img
              src={require("../../Images/Performance-testing-1.jpg")}
              style={{ width: "100%" }}
            />
          </div>
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                We offer load testing services for applications across domains
                and technologies, including web-based n-tier applications,
                client-server applications, SOA based solutions or COTS
                solutions. We have experience with all , along with expertise in
                end-to-end application performance testing including network,
                database, hardware, etc.
              </p>
              <br />
              <p>
                We publish a detailed performance testing report for the
                application with response times, break-point, peak load, memory
                leaks, resource utilization, uptime, etc. We provide clear and
                actionable application tuning recommendations and help you
                implement those working closely with various IT teams in your
                enterprise.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default PerformanceTesting;
