import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function DataMigrationTesting() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-img">
        <div className="banner-box-container-1">
          <div className="banner-box-container-2">
            <h2>
              Seamless Data Migration services ensures Data Accuracy and
              Reliability
            </h2>

            <h2 className="banner-box-container-2-h2">
              Empower Your data Warehouse With Customized Testing Approach
            </h2>

            <div
              className="banner-box-btn"
              onClick={() => {
                scrolltoFooter();
              }}
            >
              <Link to={"/contact"} className="banner-box-btn-link">
                <h2>Talk To Us</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>Data Migration Testing Services</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                Development of a Data Warehouse is a major task expected to
                yield major business benefits regarding the justification of the
                cost and efforts. However, many ETL projects fail to achieve the
                required benefits due to improper quality assurance strategy.
                ETL or Data Migration Testing should ensure the data migrated
                from heterogeneous sources to the data warehouse occurs with no
                loss of information/ data, with strict adherence to transfer
                rules, in compliance with validity checks. ETL Testing should be
                able to detect early on any problems with the source data if
                done effectively; any other inconsistencies or ambiguities
                required to guide data transformation and integration should
                also be detected during the early stages of ETL Testing. ETL
                testing should be continuous to ensure the extraction,
                transformation, and load processes are working as planned and
                the result is consistent, accurate, and complete. We are the
                leading Data Migration testing company offering quality testing
                services to our clients.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default DataMigrationTesting;
