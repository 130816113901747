import React from "react";
import "./Socials.css";
import email from "../Images/email.svg";
import facebook from "../Images/facebook.svg";
import twitter from "../Images/twitter.svg";
import linkedin from "../Images/linkedin.svg";

export default function Socials() {
  return (
    <>
      <div class="reachusatdiv">
        <div class="reachusaticonsdivcontainer">
          <div class="reachusiconsdiv">
            <a href="mailto:info@vncservices.in" class="reachusicons">
              <img class="reachusicons" src={email} />
            </a>
          </div>
          <div class="reachusiconsdiv">
            <img class="reachusicons" src={twitter} />
          </div>
          <div class="reachusiconsdiv">
            <a
              class="reachusicons"
              href="https://m.facebook.com/vncdigitalservices/"
            >
              <img class="reachusicons" src={facebook} />
            </a>
          </div>
          <div class="reachusiconsdiv">
            <a
              class="reachusicons"
              href="https://www.linkedin.com/company/vncdigital/"
            >
              <img class="reachusicons" src={linkedin} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
