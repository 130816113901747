import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function MobileApplicationTesting() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-img">
        <div className="banner-box-container-1">
          <div className="banner-box-container-2">
            <h2>
              Make Your Mobile App A Cursor To Connect Digital and Real Worlds
            </h2>

            <h2 className="banner-box-container-2-h2">
              Partner With Us To Launch Flawness Mobile Appilications On All
              Platforms
            </h2>

            <div
              className="banner-box-btn"
              onClick={() => {
                scrolltoFooter();
              }}
            >
              <Link to={"/contact"} className="banner-box-btn-link">
                <h2>Talk To Us</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>Mobile Application Testing Services</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                Mobile devices are inarguably the most disruptive technology
                invention in recent times and have had an impact on human lives
                like no other. With the number of mobile devices surpassing the
                number of human beings on earth, making your application
                mobile-ready is key to keeping today’s digital consumers happy.
              </p>
              <br />
              <p>
                Across enterprises of all sizes, mobile apps have fueled growth
                in business operations and customer services. However, with the
                variety of platforms, devices and networks, there is significant
                infrastructure required for
              </p>
            </div>
          </div>
          <div
            className="service-content-right-desc"
            style={{
              position: "relative",
              width: "80%",
              height: "auto",
              display: "flex",
              alignSelf: "center",
            }}
          >
            <img
              src={require("../../Images/mobile-app-img-3.png")}
              style={{ width: "100%" }}
            />
          </div>
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                QualiTek (mobile testing company), with its{" "}
                <b>Advanced Mobile Test Lab</b>, extensive expertise in mobile
                testing engagements and breadth of experience in the right
                tools, ensures that the mobile testing is thorough and
                cost-effective.
              </p>

              <br />
              <p>
                Our Mobile Testing Lab has{" "}
                <b>
                  250+ physical and unlimited cloud-based devices from leading
                  brands
                </b>
                <br />
                and the ability to test with various network carriers on{" "}
                <b>2G, 3G, 4G and Wi-Fi</b>.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default MobileApplicationTesting;
