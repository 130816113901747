import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function AccessibilityTesting() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-img">
        <div className="banner-box-container-1">
          <div className="banner-box-container-2">
            <h2>
              Make Your Applications To Reach Every Corner Of The Digital World
            </h2>

            <h2 className="banner-box-container-2-h2">
              Comprehensive Accessibility Testing Services Ensures Equal Access
              To All
            </h2>

            <div
              className="banner-box-btn"
              onClick={() => {
                scrolltoFooter();
              }}
            >
              <Link to={"/contact"} className="banner-box-btn-link">
                <h2>Talk To Us</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>Accessibility Testing Services</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                Today various types of application usage have become an
                indispensable part of human lives. People across the globe
                access different types of apps to perform numerous tasks. But, a
                large segment of our society containing more than a billion
                people with certain disabilities (visual &amp; hearing
                impairment, cognitive disability, motor impairments, etc.) still
                finds it difficult to access the same apps. Thus, to ensure
                equal access to apps for all people (with disabilities &amp;
                special needs),
              </p>
              <br />
              <p>
                At QualiTek (Tx), we believe in a world that is fair to everyone
                and enable a comprehensive range of accessibility testing
                services. With high expertise and sound knowledge of
                accessibility testing regulations like W3Cs i.e.
                <strong>
                  ,WCAG 2.0, WCAG 2.1 (Level A: 30, Level AA: 20, Level AAA:
                  28), WCAG 2.2 (Level A: 34, Level AA: 24, Level AAA: 28) and
                  certain regulation such as Section 508, UK-Equality Act,
                  Stanca Act, Rights of Persons with Disabilities Act, 2016,
                  etc.
                </strong>
                , we ensure standardized accessibility practices are followed
                within the application. There are numerous benefits of
                accessibility testing for businesses, and firstly an
                accessibility compliant applicant can save businesses from
                various litigations and lawsuits.
              </p>
              Secondly, a seamlessly accessible app will also help businesses to
              increase user outreach and user engagement. Moreover, it will also
              uplift the overall brand’s image and reputation.
            </div>
          </div>
          <div className="service-content-box">
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h2>Accessibility Testing Services</h2>
            </div>
            <div
              className="service-content-right-desc"
              style={{
                position: "relative",
                width: "80%",
                height: "auto",
                display: "flex",
                alignSelf: "center",
              }}
            >
              <img
                src={require("../../Images/accessibility-testing-img-1.jpg")}
                style={{ width: "80%" }}
              />
            </div>
            <div
              className="service-content-right-desc"
              style={{
                position: "relative",
                width: "80%",
                height: "auto",
                display: "flex",
                alignSelf: "center",
              }}
            >
              <img
                src={require("../../Images/accessibility-testing-img-2.jpg")}
                style={{ width: "80%" }}
              />
            </div>
            <div
              className="service-content-right-desc"
              style={{
                position: "relative",
                width: "80%",
                height: "auto",
                display: "flex",
                alignSelf: "center",
              }}
            >
              <img
                src={require("../../Images/accessibility-testing-img-3.jpg")}
                style={{ width: "80%" }}
              />
            </div>
            <div
              className="service-content-right-desc"
              style={{
                position: "relative",
                width: "80%",
                height: "auto",
                display: "flex",
                alignSelf: "center",
              }}
            >
              <img
                src={require("../../Images/accessibility-testing-img-4.jpg")}
                style={{ width: "80%" }}
              />
            </div>
          </div>
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div
              className="service-content-left-desc"
              style={{ width: "100%", marginLeft: "5%" }}
            >
              <p>
                QualiTek's team of Usability experts will help you with the
                user-experience of your application and come up with a Usability
                test strategy based on the type of users, demographics, key
                business scenarios, etc. The team will identify a set of
                appropriate users, draft survey questions and help the targeted
                users complete the survey. The user behavior and actions will be
                monitored in real-time along with conducting interviews to get
                deeper insights about the application’s navigational scenarios
                and other options.
              </p>
              <br />
              <h2
                className="service-ul-header-h2"
                style={{ width: "100%", marginLeft: "-2%" }}
              >
                QualiTek will provide a detailed Usability analysis report -
                with the following key parameters:
              </h2>
              <ul
                className="service-differtiater-list-body"
                style={{ width: "100%", marginLeft: "5%" }}
              >
                <li className="service-differtiater-list-item">
                  Average Application Usability Rating
                </li>
                <li className="service-differtiater-list-item">
                  Usability Ratings of different countries
                </li>
                <li className="service-differtiater-list-item">
                  Detailed comments provided by individuals
                </li>
                <li className="service-differtiater-list-item">
                  Average Application Usability Rating Graph
                </li>
                <li className="service-differtiater-list-item">
                  Areas which are easy to access
                </li>
                <li className="service-differtiater-list-item">
                  Areas where improvement is required
                </li>
                <li className="service-differtiater-list-item">
                  Post-Task Average response given by users
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default AccessibilityTesting;
