import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function AITesting() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-img">
        <div className="banner-box-container-1">
          <div className="banner-box-container-2">
            <h2>
              When Powerful Technology Meets Effective Implementation and
              Internal Process Management Your Journey Leads To New Heights Of
              Business Success
            </h2>

            <h2 className="banner-box-container-2-h2">
              Embrace AI Testing To Shorten Testing Time And Release Quality
              Software Faster
            </h2>

            <div
              className="banner-box-btn"
              onClick={() => {
                scrolltoFooter();
              }}
            >
              <Link to={"/contact"} className="banner-box-btn-link">
                <h2>Talk To Us</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>AI Testing Services</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                Organizations have tried to speed up and optimize software
                testing processes with continuous releases of high-quality
                software but with the evolution of Artificial Intelligence (AI)
                enabled solutions, testing has become more challenging due to
                the complexity involved in testing AI systems. According to a
                research report, it is expected that by 2025, the value of the
                AI market is said to surpass US $100B.
              </p>
              <br />
              <p>
                Specifically, AI- based systems testing is essentially difficult
                as different input and output combinations that are fed to the
                system should be tested which is more towards a
                non-deterministic approach. Moreover, as the world is
                increasingly moving towards increased adoption of AI-powered
                smart applications, there is every need for end-to-end AI
                systems testing to ensure fully functional and high-performing
                AI systems. Hence, in order to ensure effective AI testing,
                QualiTek follows an effective, and comprehensive testing
                strategy for testing AI systems.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>QualiTek AI Application Testing Methods</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div
              className="service-content-right-img"
              style={{
                position: "relative",
                width: "80%",
                height: "auto",
              }}
            >
              <img
                src={require("../../Images/AI-img-1.png")}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>
            QualiTek Exclusive <b>AI Bots Testing</b> Process Overview
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                We also have a dedicated team of AI testers to perform testing
                of any type of AI based bots. Though there are certain
                challenges with testing of AI bots such as difficulty with
                domain specific validations, uncertainty over user
                conversations, performance issues with solicitations, BOT
                security being compromised in some, ensuring multi-channel user
                experience, etc. But, our AI testing experts perform effective
                Voice and NLP text testing, API testing, UX testing DB testing
                to deliver a fully-functional AI bot that is ready for
                functioning on its own by taking inputs from users.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>Our Approach for Testing AI Bots</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div
              className="service-content-right-img"
              style={{
                position: "relative",
                width: "80%",
                height: "auto",
              }}
            >
              <img
                src={require("../../Images/AI-img-2.png")}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="service-content-left-img">
            <img
              src={require("../../Images/AI-img-3.png")}
              style={{ width: "100%" }}
            />
          </div>
          <div className="service-content-right-desc">
            <h2 className="service-ul-header-h2" style={{ marginLeft: "-5%" }}>
              Business Benefits With Our AI Testing Of Complex AI Apps
            </h2>
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Shortens the time to run a test and quickly finds any possible
                defects.
              </li>
              <li className="service-differtiater-list-item">
                Ensures the entire testing process becomes more efficient
              </li>
              <li className="service-differtiater-list-item">
                Helps QA teams to be not overloaded with work while testing
                large volumes of data
              </li>
              <li className="service-differtiater-list-item">
                Produces test results very fast
              </li>
              <li className="service-differtiater-list-item">
                AI testing helps to be a smart assistant in running numerous
                repetitive tasks most efficiently
              </li>
              <li className="service-differtiater-list-item">
                Helps to increase testing productivity through powerful reports,
                metrics and delivers real-time insights
              </li>
              <li className="service-differtiater-list-item">
                Helps to reduce time-consuming manual testing so teams can focus
                on other complex tasks or creating innovative new features
              </li>
              <li className="service-differtiater-list-item">
                Helps to free human resources to perform more creative work in
                testing
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="service-content-left-desc">
            <h2 className="service-ul-header-h2" style={{ marginLeft: "0%" }}>
              QualiTek Differentiators with respect to AI Testing
            </h2>
            <ul
              className="service-differtiater-list-body"
              style={{ marginLeft: "5%" }}
            >
              <li className="service-differtiater-list-item">
                Strong experience in major tools to focus on Intelligent
                automated continuous testing
              </li>
              <li className="service-differtiater-list-item">
                20+ Person Years of experience in using various tools for
                providing Intelligent Automation Solution
              </li>
              <li className="service-differtiater-list-item">
                Extensive experience in enabling CI/CD pipeline using industry
                tools – based on QualiTek Automated accelerators
              </li>
              <li className="service-differtiater-list-item">
                100+ Test consultants for next-gen tools
              </li>
              <li className="service-differtiater-list-item">
                Global delivery team for project execution and availability
              </li>
              <li className="service-differtiater-list-item">
                Expertise in using AI in Test Automation with partner tools and
                in-house accelerators
              </li>
            </ul>
          </div>
          <div
            className="service-content-right-img"
            style={{ position: "relative", marginTop: "100px" }}
          >
            <img
              src={require("../../Images/AI-img-4.png")}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default AITesting;
