import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function IoTTesting() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-img">
        <div className="banner-box-container-1">
          <div className="banner-box-container-2">
            <h2>
              Achieve Complete Connected Applications Functionality Using Our
              Services
            </h2>

            <h2 className="banner-box-container-2-h2">
              Connect To Our IOT Testing Services
            </h2>

            <div
              className="banner-box-btn"
              onClick={() => {
                scrolltoFooter();
              }}
            >
              <Link to={"/contact"} className="banner-box-btn-link">
                <h2>Talk To Us</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>IoT Testing Services</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                The Internet of Things (IoT) has enabled enterprises to build
                smart and connected applications to support multiple dimensions
                of customer service and engagement.
              </p>
              <br />
              <p>
                According to recent reports, the number of connected “Internet
                of Things” is expected to reach 55 billion by 2025. The IoT
                Testing market was valued at USD 665.47 million in 2019 and is
                expected to reach USD 3212.10 million by 2025, at a CAGR of 30%
                over the forecast period 2020 – 2025.
              </p>
              <br />
              <p>
                Owing to these developments in IoT, smart and connected
                solutions will enable enterprises to monitor client’s real-world
                experiences through software and provide services across the
                complete range of business and consumer scenarios.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="service-content-box"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="service-content-left-desc">
            <h2 className="service-ul-header-h2" style={{ marginLeft: "3%" }}>
              IoT Applications Enable Real-Time Reporting to Make Informed
              Decisions
            </h2>
            <ul
              className="service-differtiater-list-body"
              style={{ marginLeft: "5%" }}
            >
              <li className="service-differtiater-list-item">
                With sensors installed all over, the world is turning out to be
                progressively connected. With sensors progressively connecting
                all physical devices and applications, there is an increased
                need to revamp the traditional testing approach. The IoT is
                about reporting real-time data, permitting users to make quick,
                and informed decisions.
              </li>
              <li className="service-differtiater-list-item">
                To ensure that your apps and devices stand up to the real-time
                scenarios, it is important to move part of your testing into the
                real world with poor connectivity and less than normal
                conditions established. This will ensure the level of quality
                regardless of what they are put through.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          className="service-content-right-img"
          style={{
            position: "relative",

            width: "80%",
            height: "auto",
            display: "flex",
            alignSelf: "center",
          }}
        >
          <img
            src={require("../../Images/IoT-img-2.jpg")}
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div className="service-content-box">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                Across enterprises of all sizes, IoT have fueled growth in
                business operations and customer services. In coming years,
                Companies can entirely run their businesses through algorithms
                and automation, such as making self-driving cars safer, helping
                protect digital identities and even track billions of devices on
                Internet of Things (IoT).
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>Use Of IoT across various Industries</h2>
        </div>

        <div
          className="service-content-right-img"
          style={{
            position: "relative",
            width: "80%",
            height: "auto",
          }}
        >
          <img
            src={require("../../Images/IoT-img-3.jpg")}
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>Performance Testing Services</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                The reliable and consistent performance of your business
                critical applications is the key to success of your enterprise.
                However, performance issues within an application are often
                identified in live scenarios which result in considerable damage
                to the reputation of your brand. That is where formal web
                performance testing of the applications is necessary to
                ascertain application behavior at peak performance and
                scalability levels.
              </p>
              <p>
                QualiTek help you predict application behavior and performance
                in a simulated, real-life environment, thus saving you from
                unpleasant events later, which frustrate customers and impact
                profitability. We ensure the application is responsive and
                reliable enough to respond to peak load days like a black Friday
                sale and can also scale to other future events like product
                release, acquisitions, new clients, regulatory changes and
                ongoing business growth. We also ensure the applications can
                meet any
                <br />
                contractual obligations and SLAs for performance.
              </p>
            </div>
          </div>
          <div
            className="service-content-right-img"
            style={{
              position: "relative",
              width: "80%",
              height: "auto",
              display: "flex",
              alignSelf: "center",
            }}
          >
            <img
              src={require("../../Images/IoT-img-4.jpg")}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default IoTTesting;
