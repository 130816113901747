import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "./Contact.css";
import "./AboutUs.css";
import BoxAlphabets from "../BoxAlphabets";

function AboutUs() {
  const box = [];

  var letterObj = {};
  letterObj["backgroundcolor"] = "#f6f6f6";
  letterObj["key"] = "A";
  letterObj["word"] = "Agility";
  letterObj["keycolor"] = "#FD7F20";
  letterObj["wordcolor"] = "#444";
  letterObj["fontsize"] = 8;

  box.push(letterObj);

  var letterObj1 = {};
  letterObj1["backgroundcolor"] = "#d3d3d3";
  letterObj1["key"] = "C";
  letterObj1["word"] = "Commitment";
  letterObj1["keycolor"] = "#FD7F20";
  letterObj1["wordcolor"] = "#444";
  letterObj1["fontsize"] = 8;

  box.push(letterObj1);

  var letterObj2 = {};
  letterObj2["backgroundcolor"] = "#f6f6f6";
  letterObj2["key"] = "T";
  letterObj2["word"] = "Transparency";
  letterObj2["keycolor"] = "#FD7F20";
  letterObj2["wordcolor"] = "#444";
  letterObj2["fontsize"] = 8;

  box.push(letterObj2);

  var letterObj3 = {};
  letterObj3["backgroundcolor"] = "#d3d3d3";
  letterObj3["key"] = "I";
  letterObj3["word"] = "Intigrity";
  letterObj3["keycolor"] = "#FD7F20";
  letterObj3["wordcolor"] = "#444";
  letterObj3["fontsize"] = 8;

  box.push(letterObj3);

  var letterObj4 = {};
  letterObj4["backgroundcolor"] = "#f6f6f6";
  letterObj4["key"] = "O";
  letterObj4["word"] = "Ownership";
  letterObj4["keycolor"] = "#FD7F20";
  letterObj4["wordcolor"] = "#444";
  letterObj4["fontsize"] = 8;

  box.push(letterObj4);

  var letterObj5 = {};
  letterObj5["backgroundcolor"] = "#d3d3d3";
  letterObj5["key"] = "N";
  letterObj5["word"] = "Nonstop Innovation";
  letterObj5["keycolor"] = "#FD7F20";
  letterObj5["wordcolor"] = "#444";
  letterObj5["fontsize"] = 8;

  box.push(letterObj5);

  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };
  return (
    <div>
      <div className="aboutus-container"></div>
      <div className="aboutus-box">
        <div className="aboutus-box-banner">
          <h1 className="aboutus-box-h1">
            We are shaped not by what we say, but what we do
          </h1>
        </div>

        <p className="aboutus-box-p">
          What is culture but a shared set of beliefs, values and practices? Our
          culture is the foundation of our success and it is completely people
          oriented, touching lives and making a difference to employees,
          stakeholders, customers and partners alike. We believe that excellence
          is not a destination but a journey of continuous improvement and we
          keenly promote an open culture, encouraging feedback, and actively
          transforming it into action.
        </p>
      </div>

      <div className="our-journey-container">
        <div className="button-reachus">
          <h2 className="button-reachus-h2">Our Story</h2>
        </div>

        <div className="journey-container">
          <div className="journey-container-line"></div>

          <div className="journey-container-box">
            <div className="journey-box">
              <p>
                QualiTek's journey with technology began in 2017 (As VNC Digital
                Services) with the strong leadership and progressive vision of
                Management with an agile, flexible and industrious team that
                empowers your business with cutting edge technology solutions.
              </p>
            </div>
            <div className="journey-container-line-2"></div>
            <div className="journey-point-left">
              <img
                src={require("../../Images/our_journey_img_2.png")}
                className="journey-point-img"
              />
            </div>
          </div>

          <div
            className="journey-container-box"
            style={{
              alignSelf: "flex-end",
              marginLeft: "0%",
              marginRight: "10%",
            }}
          >
            <div className="journey-point-right">
              <img
                src={require("../../Images/our_journey_img_3.png")}
                className="journey-point-img"
              />
            </div>
            <div className="journey-container-line-2"></div>

            <div className="journey-box">
              <p>
                By 2021 Qualitek crossed a workforce of 100 And chosen TaaS
                (Testing As a Service) and DevOps as the main services
                provided.It gained a large clientele and helped them to gain a
                competitive edge through outstanding testing services ,reduced
                costs and enhanced productivity. Maintained high standards to
                achieve clientele business outcomes.
              </p>
            </div>
          </div>

          <div className="journey-container-box">
            <div className="journey-box">
              <p>
                2022, Qualitek has become a strategic partner with Xcel Corp. in
                delivering Quality Services.
              </p>
            </div>
            <div className="journey-container-line-2"></div>
            <div className="journey-point-left">
              <img
                src={require("../../Images/our_journey_img_4.png")}
                className="journey-point-img"
              />
            </div>
          </div>

          <div
            className="journey-container-box"
            style={{
              alignSelf: "flex-end",
              marginLeft: "0%",
              marginRight: "10%",
            }}
          >
            <div className="journey-point-right">
              <img
                src={require("../../Images/our_journey_img_5.png")}
                className="journey-point-img"
              />
            </div>
            <div className="journey-container-line-2"></div>
            <div className="journey-box">
              <p>
                We believe in a process based approach to balance our technology
                prowess while we help businesses across diverse platforms and
                industries. With our wide range of engagement, execution and
                delivery models, we are able to customize solutions to help you
                solve your challenges and reach out for growth.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          top: "3%",
          width: "100%",
          height: "auto",
          marginBottom: "25px",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f8f9fa",
        }}
      >
        <div className="button-reachus">
          <h2 className="button-reachus-h2">Our Leadership</h2>
        </div>

        <div className="leadership-container">
          <div className="journey-container-box">
            <div className="leadership-box">
              <p>
                <b>BalaKrishna Mattupalli</b> stepped into the technology realm
                at a very young age and, having nurtured this passion for
                technology consistently and persistently, has emerged as a
                technology leader equipped with the skills and knowledge to
                provide the right solutions for business growth.
              </p>
              <br />

              <p>
                An Engineering Degree at Nagarjuna University and a wealth of 16
                years experience across various industries have trained him to
                solve real world business problems with practised knowledge and
                intuitive vision. Bala, with his penchant for innovation and
                business, combined his technology and business know-how to
                deliver high quality solutions and services at low costs.
              </p>
              <br />

              <p>
                Bala's systematic and progressive outlook have steered QualiTek
                toward becoming a provider of proprietary Testing and Devops
                solutions. An accomplished sports person at heart, Bala has also
                been able to direct the energy and strategy of his game toward
                crafting solutions for his clients that lead them to success.
              </p>
            </div>
          </div>
          <div className="leadership-img-box">
            <img
              src={require("../../Images/Balu.jpeg")}
              className="leadership-img"
            />
            <div className="leadership-tag">
              <h1>
                BALAKRISHNA MATTUPALLI
                <br />
                <p> Co-Founder & Managing Director</p>
              </h1>
            </div>
          </div>
        </div>

        <div
          className="leadership-container"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="leadership-img-box" style={{ marginLeft: "10%" }}>
            <img
              src={require("../../Images/Jay.jpeg")}
              className="leadership-img"
            />
            <div className="leadership-tag-left">
              <h1>
                JAYACHANDRAN JANARDHAN
                <br />
                <p>Co-Founder & Executive Director</p>
              </h1>
            </div>
          </div>
          <div className="journey-right-container-box">
            <div className="leadership-box">
              <p>
                <b>Jayachandran Janardhan’s</b> work in the technology domain
                includes over 18 years of global experience in heading Business
                Transformation, Client Engagement and Enterprise Managed
                Services projects. His work spans Anything and Everything around
                TaaS (Testing as a service) and Cloud Services across the
                domains.
              </p>
              <br />

              <p>
                Through his time with QualiTek, Jay has led a number of
                initiatives, including leading and establishing QualiTek’s TaaS
                and DevOps practice. Applying his in-depth expertise across
                multiple tech stacks and frameworks, he has been able to take
                QualiTek from being just another software company to a stand-out
                performer across various projects. His hands-on knowledge of
                modern application architecture, and his expertise with latest
                cloud services, have helped him build QualiTek into a formidable
                service providing company.
              </p>
              <br />

              <p>
                Jay’s hands-on approach and constant thirst for knowledge
                provide inspiration to his team to always aim higher and go
                beyond the ordinary. His infectious enthusiasm and unwavering
                commitment to quality are the reasons he plays a pivotal role at
                QualiTek.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          backgroundColor: "#feffff",
          width: "80%",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "10%",
          marginRight: "10%",
          marginBottom: "100px",
        }}
      >
        <div className="client-container-banner" style={{ marginTop: "3%" }}>
          <img
            className="client-container-banner-logo"
            src={require("../../Images/QualiTek-Symbol.png")}
          />
          <h2 className="client-banner-h2"> -Core Values</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "80%",
            marginTop: "50px",
          }}
        >
          <BoxAlphabets hello={box} />
        </div>

        <div
          style={{
            minHeight: "300px",
            maxHeight: "300px",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            backgroundColor: "#feffff",
            width: "80%",
            alignItems: "center",
            justifyContent: "flex-start",
            marginLeft: "10%",
            marginRight: "10%",
            marginTop: "50px",
            position: "relative",
            marginBottom: "50px",
          }}
        >
          <h1
            className="client-banner-h2"
            style={{ display: "flex", alignSelf: "flex-start" }}
          >
            Mission
          </h1>
          <div className="mission-container">
            <p>
              As an independent Testing services entity, our mission is to
              create tailor-made solutions for solving our customer business
              challenges, regradless of frontiers and geographic challenges by
              providing excellent products and top notch quality services
            </p>
            <div className="vision-container">
              <p>
                As a global software company we will develop top-tier
                development solutions, promote our client's innovative products,
                create efficient teams and open avenues for more open dialog
              </p>
            </div>
            <h1
              className="client-banner-h2"
              style={{
                position: "absolute",
                bottom: "5%",
                display: "flex",
                left: "88%",
                alignSelf: "flex-end",
              }}
            >
              Vision
            </h1>
          </div>
        </div>
      </div>

      <div className="client-container">
        <div className="client-container-banner">
          <img
            className="client-container-banner-logo"
            src={require("../../Images/QualiTek-Symbol.png")}
          />
          <h2 className="client-banner-h2"> -Clients</h2>
        </div>

        <div className="client-banner-img"></div>

        <div style={{ width: "100%" }}>
          <img
            src={require("../../Images/clients.jpg")}
            style={{
              height: "100%",
              width: "100%",
              resize: "both",
            }}
          />
        </div>
      </div>

      <div className="client-container">
        <div className="client-container-banner">
          <img
            className="client-container-banner-logo"
            src={require("../../Images/QualiTek-Symbol.png")}
          />
          <h2 className="client-banner-h2"> -Partners</h2>
        </div>

        <div className="partner-banner-img"></div>

        <div className="partner-section">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f6f6f6",
            }}
          >
            <img
              src={require("../../Images/XCEL-Corp-logo.png")}
              style={{
                objectFit: "contain",
              }}
            />
          </div>
          <div className="partner-description">
            <p>
              XCEL Solutions Corp is a global IT service provider that delivers
              versatile solutions by optimizing technology to achieve corporate
              objectives. They tackle the toughest challenges for businesses
              with a potent amalgamation of strategic planning, knowledge, and
              skills to provide high-quality solutions that best fit every
              client’s technological as well as business needs. They have the
              track record of success servicing Fortune 500, Inc. 1000 and other
              companies worldwide with over 15+ years of experience and a strong
              and stable financial history.
            </p>
            <br />
          </div>
        </div>
      </div>

      <div className="infrastructure-container">
        <div className="client-container-banner">
          <img
            className="client-container-banner-logo"
            src={require("../../Images/QualiTek-Symbol.png")}
          />
          <h2 className="client-banner-h2"> -Infrastructure</h2>
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div className="infrastructure-box">
            <img
              className="infrastructure-image"
              src={require("../../Images/Infrastructure-img-1.png")}
            />
            <h1 className="infrastructure-h2">QualiTek, Bangalore Office</h1>
            <p>
              Ardante Office One
              <br />
              Hoodi Main Rd, Hoodi.
              <br /> Bangalore - 560048
            </p>
          </div>
          <div className="infrastructure-box">
            <img
              className="infrastructure-image"
              src={require("../../Images/Infrastructure-img-2.png")}
            />
            <h1 className="infrastructure-h2">QualiTek, Chennai Office</h1>
            <p>
              Prince Info Park
              <br />
              Ambattur
              <br /> Chennai - 600058
            </p>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default AboutUs;
