import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function TestAutomation() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            Leverage Test Automation and CI/CD Expertise For achieving 'Quality
            At Speed'
          </h2>

          <h2 className="banner-box-container-2-h2">
            Create DevOps-Aligned Test Automation Solution With Ready
            Accelerators And Latest best practices
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div className="service-header-box">
          <h2>Automation Testing Services</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="service-content-desc">
            <div className="service-content-left-desc">
              <p>
                Test automation helps in reducing regression testing time and
                cutting down the time to market with significant cost savings on
                a long-term basis. However, a clear <b>automation strategy</b>{" "}
                and roadmap are key to ensuring the right return on investment
                on your automation initiatives. With disparate application
                architecture, multiple environments, third-party integrations,
                and multiple user devices, a standardized and consistent
                automation approach is needed to ensure high reusability, ease
                of maintenance and lower upfront costs.
              </p>
              <br />
              <p>
                With the experience of advising clients on test automation and
                architecting their automation journey, QualiTek is one of the
                leading automation testing companies that generates the value
                you always wanted through test automation.
              </p>
              <br />
              <p>
                Our test automation solutions support your business objectives
                by designing an automation strategy aligned with enterprise
                goals. We carry out a detailed tool evaluation exercise to
                recommend an optimized tool inventory to suit your needs and
                budget.{" "}
              </p>
              <br />
              <p>
                With the rise of DevOps, the silos of the software industry
                (development and operations team) are breaking down. A
                combination of a cultural shift and the implementation of the
                right tools, technologies, and best practices are required for
                organizations to evolve to DevOps and are able to move with
                greater velocity than ever before. QualiTek can take you through
                this journey to optimize your processes, tools and scale up your
                people with our nice Devops Services viz. DevOps Advisory
                Services, Infrastructure as Code advisory and implementation
                services as well as CI/CD Implementation services.
              </p>
              <br />
              <p>
                QualiTek’s <strong>Test Automation Framework</strong> helps test
                the emulation &amp; behavior of multiple API &amp; Cloud-based
                apps, service-oriented architectures (SOA), and microservices,
                thereby making all applications under test (AUT) available for
                development &amp; testing purposes. Our Service Virtualization
                practice enables enterprises to quickly simulate and easily
                manage test environments for bringing high-quality software to
                the market faster at significantly lower costs.
              </p>
            </div>
          </div>
          <div className="service-content-right-img">
            <img
              src={require("../../Images/testautomation-img-1.png")}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>Test Automation Approach</h2>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="service-content-left-img">
            <img
              src={require("../../Images/testautomation-img-2.png")}
              style={{ width: "100%" }}
            />
          </div>
          <div className="service-content-left-desc">
            <h2 className="service-ul-header-h2">
              Our balanced Test&nbsp;Automation approach uses:
            </h2>
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Assessment of your automation needs and application landscape.
              </li>
              <li className="service-differtiater-list-item">
                Assessment of your existing automation solution. (if any)
              </li>
              <li className="service-differtiater-list-item">
                Tool selection, Proof of Concept and implementation.
              </li>
              <li className="service-differtiater-list-item">
                Automated test script creation and execution.
              </li>
              <li className="service-differtiater-list-item">
                Creation of regression test suites with flexible execution
                options.
              </li>
              <li className="service-differtiater-list-item">
                Training and handover of automation test pack to client for
                future maintenance.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div className="service-header-box">
          <h2>Test Automation Benefits</h2>
        </div>

        <div
          className="service-content-right-img"
          style={{
            position: "relative",
            width: "80%",
            height: "auto",
          }}
        >
          <img
            src={require("../../Images/test-automation-benefits.png")}
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>Test Automation Framework</h2>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="service-content-left-img">
            <img
              src={require("../../Images/test-automation-img-3.png")}
              style={{ width: "100%" }}
            />
          </div>
          <div className="service-content-desc">
            <div className="service-content-right-desc">
              <p>
                Our Automation framework contains ‘out-of-the-box’ and
                best-in-class features for test automation. including rich
                custom reporting, third party integrations, configurable
                execution options, etc. The framework helps configure/ create
                test suites by combining various automated tests and making
                those test suites available for execution.
              </p>
              <br />
              <p>
                It inculcates industry best practices and features and can
                drastically reduce the effort to kick-start automation. As one
                of the best automation testing companies, we have dedicated
                teams with core expertise on all industry-leading tools like
                Selenium, HP UFT, Coded UI, TestComplete, Ranorex, Appium, etc.
                and can support test automation with a scripting language your
                team is comfortable with.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div className="service-header-box">
          <h2>QualiTek Differentiators</h2>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="service-content-left-img">
            <img
              src={require("../../Images/testautomation-img-5.png")}
              style={{ width: "100%" }}
            />
          </div>

          <div className="service-content-right-desc">
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Setting things right from the beginning with detailed automation
                assessment to come up with a clear automation strategy & roadmap
              </li>
              <li className="service-differtiater-list-item">
                Ready to deploy, best in industry automation frameworks
                implemented with customizations as per your needs.
              </li>
              <li className="service-differtiater-list-item">
                Focus on enhancing reusability and ease of maintenance for lower
                ongoing costs.
              </li>
              <li className="service-differtiater-list-item">
                Support integration with test management, defect management and
                continuous integration tools providing complete traceability and
                lifecycle automation.
              </li>
              <li className="service-differtiater-list-item">
                Proven end to end selenium automation testing process
              </li>
              <li className="service-differtiater-list-item">
                Expertise in all leading open source and commercial automation
                tools for the web, desktop, and mobile applications.
              </li>
              <li className="service-differtiater-list-item">
                Flexibility in executing tests at various levels, i.e.,
                application level, suite level, test case level, etc. across
                different testing environments.
              </li>
              <li className="service-differtiater-list-item">
                Custom test execution reports with a rich interface delivered to
                all stakeholders.
              </li>
              <li className="service-differtiater-list-item">
                Expertise on all leading tools for application lifecycle
                management, test management, defect management, test data
                management etc.
              </li>
              <li className="service-differtiater-list-item">
                Structured project communications, including daily, weekly, and
                monthly status reports with detailed test execution status and
                analysis delivered through a customized dashboard.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default TestAutomation;
