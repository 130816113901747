import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function RPATesting() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-img">
        <div className="banner-box-container-1">
          <div className="banner-box-container-2">
            <h2>
              Achieve Robotic Functionality Compliant Applications With Our
              Testing Services
            </h2>

            <h2 className="banner-box-container-2-h2">
              Launch Bug Free Robotic Applications With Coincidence
            </h2>

            <div
              className="banner-box-btn"
              onClick={() => {
                scrolltoFooter();
              }}
            >
              <Link to={"/contact"} className="banner-box-btn-link">
                <h2>Talk To Us</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>RPA Testing Services</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                Robotic Process Automation (RPA) is the best-fit technology for
                today’s businesses that allows configuring a robot to integrate
                into human actions to effectively execute repeatable business
                processes. Evidently, for enterprises, this technology helps to
                boost operational efficiency by automating repetitive business
                processes with a software bot, thus eliminating human
                intervention.
              </p>
              <p>
                The global RPA market size has been valued at $1.40 B in 2019
                and is expected to reach $11 B by 2027. Most of the CXOs across
                the globe are adopting this technology for saving costs and for
                improving their business efficiency using these RPA bots.
                Moreover, organizations across domains of healthcare, insurance,
                banking, etc. are reaping immense benefits implementing RPA.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                Evidently, for these RPA robots to perform seamlessly, they
                should be tested effectively to ensure they are seamless in
                performing their tasks. Moreover, as these RPA implementations
                usually consist of heterogeneous applications, components, and
                technologies that run on multiple operating systems, hence
                enabling end-to-end testing is essential.
              </p>
              <br />
              <p>
                QualiTek has expertise in enabling RPA testing services with
                in-house RPA certified testing specialists. Our teams follow a
                well-articulated RPA testing process to test robots leveraging
                both Functional and Non-Functional testing. We follow a proper
                automation testing strategy starting from test data creation to
                triggering the bot and ensure these bots work articulated as per
                the business process validations for which they are leveraged.
                We also perform testing leveraging open source and commercial
                automation testing tools to ensure bots effective performance.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>Functional Testing of RPA Bots</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                Our testers perform bot testing by taking into consideration all
                scenarios including invoked automatic activities in real-time,
                so as to ensure, bots perform seamlessly as per the defined
                business processes. The bots are tested leveraging automation
                tools extensively to handle exceptions also as these bots need
                to perform seamlessly even while exceptions arise as they
                perform business processes.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>Performance Testing of RPA Bots</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                Bots performance plays a key role in evaluating and identifying
                the time bots take to complete automated business processes. Our
                performance testers identify and validate bot performance
                related to business processes leveraging automated performance
                testing tools. Also monitor bot utilization time and identifies
                issues if any and ensures bot performs seamlessly.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default RPATesting;
