import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function TestDataManagement() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-img">
        <div className="banner-box-container-1">
          <div className="banner-box-container-2">
            <h2>
              Achieve Functionality Compliant Applications With Our Manual
              Testing Services
            </h2>

            <h2 className="banner-box-container-2-h2">
              Launch Bug Free Applications With Confidence
            </h2>

            <div
              className="banner-box-btn"
              onClick={() => {
                scrolltoFooter();
              }}
            >
              <Link to={"/contact"} className="banner-box-btn-link">
                <h2>Talk To Us</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>Test Data Management Services</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                With increasing test data volumes, diverse data types, complex
                relationships, and data sensitivity, most enterprises face
                significant challenges with test data management. Having good
                quality data is key to testing enterprise functionality
                correctly and efficiently in the test environment. Copying
                production data to be used for the test can expose the company
                to numerous privacy and compliance risks. Without a strong test
                data management strategy, you may jeopardize your testing cycles
                and pile up huge infrastructure costs.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>
            The test data management solution will broadly address the following
            three aspects:
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  Simulating real user interaction with the application
                </li>
                <li className="service-differtiater-list-item">
                  Simulating exceptional scenarios
                </li>
                <li className="service-differtiater-list-item">
                  Generating real-time load on the system during non-functional
                  testing
                </li>
              </ul>
            </div>
          </div>
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                Qualitek has immense experience in all the above 3 methodologies
                and will be able to help any organization to perform them and
                deliver a great quality product
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default TestDataManagement;
