import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function DataWarehousingTesting() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-img">
        <div className="banner-box-container-1">
          <div className="banner-box-container-2">
            <h2>
              End-to-End specialized Services help to get a Holistic View of
              Your Data’s Health
            </h2>

            <h2 className="banner-box-container-2-h2">
              Protect Your Data Loss And Embrace Effective ETL Testing Strategy
            </h2>

            <div
              className="banner-box-btn"
              onClick={() => {
                scrolltoFooter();
              }}
            >
              <Link to={"/contact"} className="banner-box-btn-link">
                <h2>Talk To Us</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-box">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>Data WareHouse and ETL Testing services</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                Historical data is becoming a key tool for decision-making at
                enterprises of all levels. With burgeoning data, most
                organizations are investing in building a robust data warehouse
                using the latest tools. However, testing the data accuracy,
                performance, and security becomes complicated and needs a
                comprehensive approach to ensure the success of your DW and BI
                implementation.
              </p>
              <p>
                QualiTek has extensive experience in Analytics Testing, DW, and
                Big Data testing engagements and addresses the unique challenges
                of DW and Big data analytics testing. We test the DW
                applications at all levels, starting from data source to the
                front-end BI applications and ensure the issues are detected at
                the early stages of testing. We ensure that the Big Data testing
                solution is adequately automated and scalable to meet future
                business needs.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default DataWarehousingTesting;
